export enum IframeEvents {
    SIGNUP_LOGIN = 'signup_login',
    SET_PARENT_URL = 'set_parent_url',
}

export const WIDGET_CONSTANTS = {
    WIDGET_QUESTION_POPUP: 'widget_question_popup',
    STYLE_ASSISTANT_SOURCE: 'style_assistant_source',
    STYLE_ASSISTANT_SOURCE_CONVERSATION: 'style_assistant_source_conversation_id',
    TAFFI_WIDGET_OPENED: 'taffi_widget_opened',
    WIDGET_READY_TO_HANDLE_EVENTS: 'widget_ready_to_handle_events',
    SEARCH_WIDGET_GET_RECOMMENDATIONS: 'search_widget_get_recommendations',
    SEARCH_WIDGET_RECOMMENDATIONS: 'search_widget_recommendations',
    SET_SEARCH_WIDGET_GENDER: 'set_search_widget_gender',
    REQUEST_CHECK_PRODUCT_URLS: 'request_check_product_urls',
    RESULT_CHECK_PRODUCT_URLS: 'result_check_product_urls',
    REQUEST_STYLING_PRODUCT: 'request_styling_product',
    RESULT_STYLING_PRODUCT: 'result_styling_product',
};
