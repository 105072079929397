export const TAFFI_DEV_MODE = 'taffiDevMode';
export const STORE_DEV_MODE_ATTR = 'taffi_store_dev_mode_attr';
export const devModeAllowedKeys = [
    'approved',
    'showTaffiWidget',
    'showFloatingButton',
    'styleLayoutVersion',
    'enableStyleWidget',
    'enableListingStyles',
    'enableSearchWidget',
    'enableSingleProductStyling',
];
